div.welcome-container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    padding: 8em;
    top: 0;
    left: 0;
}

div.welcome-container>#cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

@media (min-width:961px) {
    div.welcome-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
}