div.tapas-tile-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transform: scale(1);
    transition: transform 0.6s, color 0.6s;
}

div.tapas-tile-wrap:hover {
    transform: scale(1.15);
    transition: transform 0.6s, color 1.2s;
    cursor: pointer;
}

text.tapas-tile-text {
    font-family: 'Jaapokki', sans-serif;
    font-size: x-large;
    margin-top: 0.5em;
}